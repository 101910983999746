<script lang="ts">
  import routes   from './router'
  import { link } from 'svelte-spa-router'
  import Router   from 'svelte-spa-router'
  import { Flex, Center } from '@svelteuidev/core'
  import active   from 'svelte-spa-router/active'

  async function init() {
    try {
      const res =  await fetch('/api/version')
      const json: any = await res.json()
      console.log(json?.version)
    } catch (error) {
      console.log('0.0.0');
    }
  }

  init()
  
</script>

<main>
  <nav>
    <Flex class="nav" justify="space-between" wrap="nowrap">
      <Center><div class="title">IT WORKER</div></Center>
      <Center>
        <ul class="nav-list">
          <li class="nav-item"><a class="nav-link text-uppercase" href="/" use:link use:active>首页</a></li>
          <li class="nav-item"><a class="nav-link text-uppercase" href="/work" use:link use:active>业务范围</a></li>
          <li class="nav-item"><a class="nav-link text-uppercase" href="/show" use:link use:active>项目展示</a></li>
          <li class="nav-item"><a class="nav-link text-uppercase" href="/about" use:link use:active>联系方式</a></li>
        </ul>
      </Center>
    </Flex>
  </nav>
  <header>
    <section class="header">

    </section>
  </header>
  <Router {routes} />
  <footer>
    <Flex justify="space-around" direction="column">
      <Center>
        © 2024 dongkunshan. All rights reserved.</Center>
      <Center>
        网站备案信息：<a href="https://beian.miit.gov.cn/">京ICP备17011240号-1</a>
      </Center>
    </Flex>
  </footer>
</main>

<style lang="scss">
  @import 'assets/style/reset.css';

  main {
    width: 100%;
    min-height: 100vh;
  }

  .nav {
    height: 30px;
    margin: 10px auto;
  }

  .nav-list {
    margin-right: 50px;
  }

  li {
    display: inline-block;

    a {
      color: rgba(#000, .65);
      font-size: 1.25rem;
      padding: 2rem 0.5rem;
      transition: .5s;
      border-top: 4px solid transparent;
    }

    a:hover {
      color: rgba(79,  191, 168, .65);
      transition: .5s;
      font-size: 1.5rem;
    }
  }

  .title {
    height: 80px;
    line-height: 80px;
    margin-left: 50px;
    font-size: 1.75rem;
    font-weight: 700;
    color: rgb(79,  191, 168);
  }

  :global(h1) {
    display: inline-block;
    font-size: 1.25rem;
    font-weight: 700;
    padding: 1rem 0;
    letter-spacing: 0.1rem;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
  }

  .header {
    height: 100px;
    background: url(assets/img/home.jpg) center center no-repeat;
    background-image: cover;
  }

  footer {
    color: #fff;
    padding: 30px 0;
    background: rgb(34, 34, 34);

    a {
      color: #fff;
    }
  }

  :global(a.active) {
    color: rgb(79,  191, 168) !important;
  }
</style>
