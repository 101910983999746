<script lang="ts">
  import { Card, Grid, Badge, Image, Paper } from '@svelteuidev/core'

</script>

<div class="main">
  <h1>网站技术架构介绍</h1>
  <Grid>
    <Grid.Col span={4}>
      <Card padding="lg" shadow="lg">
        <Card.Section padding="lg">
          <div class="title">前端</div>
        </Card.Section>
        <ul>
          <li><Badge>svelte</Badge></li>
          <li><Badge>svelte-router-spa</Badge></li>
          <li><Badge>svelte-ui</Badge></li>
          <li><Badge>quark</Badge></li>
        </ul>
      </Card>
    </Grid.Col>
   <Grid.Col span={4}>
    <Card padding="lg" shadow="lg">
      <Card.Section padding="lg">
        <div class="title">服务端</div>
      </Card.Section>
      <ul>
        <li><Badge>nginx</Badge></li>
        <li><Badge>http2</Badge></li>
        <li><Badge>https</Badge></li>
        <li><Badge>nestjs</Badge></li>
      </ul>
    </Card>
   </Grid.Col>
   <Grid.Col span={4}>
    <Card padding="lg" shadow="lg">
      <Card.Section padding="lg">
        <div class="title">业务端</div>
      </Card.Section>
      <ul>
        <li><Badge>golang</Badge></li>
        <li><Badge>mysql</Badge></li>
        <li><Badge>redis</Badge></li>
        <li><Badge>mongodb</Badge></li>
      </ul>
    </Card>
  </Grid.Col>
  </Grid>
  <h1>页面性能数据</h1>
  <Paper>
    <Image src={"page.png"}></Image>
  </Paper>
  <h1>并发压测数据</h1>
  <Paper>
    敬请期待
  </Paper>
</div>

<style lang="scss">
  .main {
    text-align: center;
    padding: 1em;
    max-width: 240px;
    margin: 0 auto;
  }

  .title {
    color: #fff;
    font-size: 1.25rem;
    letter-spacing: .5rem;
    height: 50px;
    line-height: 50px;
    background-color: rgb(71, 191, 168);
  }

  ul {
    list-style: none;

    li {
      text-align: left;
    }
  }

  .textarea {
    width: 400px;
    border: solid 1px black;
  }

  .content {
    display: flex;
  }

  .radioGroup {
    display: flex;
    font-size: 20px;
  }

  :root {
    --radio-size: 20px;
  }

  @media (min-width: 640px) {
    .main {
      max-width: none;
    }
  }
</style>